// import Contact from './components/Contact';
import Header from './components/Header';
import Hero from './components/Hero';

function App() {
  return (
    <>
      <Header />
      <main>
        <Hero />
        {/* <Contact /> */}
      </main>
    </>
  );
}

export default App;
